import { Fab, FormControl, FormGroup, TextField, Tooltip, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useTagSelector } from "./Hooks/UseTagSelector"

/**
 * @param {{ searchRecipe: (search: { searchTerm: string, searchBook: string, tags: any[] }) => void }} props
 * @returns {JSX.Element}
 */
export const SearchRecipe = (props) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [searchBook, setSearchBook] = useState("")
  const [tags, setTags] = useState([])

  const tagSelectorRef = useTagSelector(tags, setTags)

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        if (!searchTerm && !searchBook && !tags.length) {
          return
        }

        props.searchRecipe({ searchTerm, searchBook, tags })
      }}
    >
      <FormGroup>
        <FormControl margin="normal">
          <TextField
            variant="outlined"
            label="Recipe Name"
            value={searchTerm || ""}
            onChange={(event) => {
              event.stopPropagation()
              setSearchTerm(event.target.value || "")
            }}
          />
        </FormControl>
        <FormControl margin="normal">
          <TextField
            variant="outlined"
            label="Recipe Book"
            value={searchBook || ""}
            onChange={(event) => {
              event.stopPropagation()
              setSearchBook(event.target.value || "")
            }}
          />
        </FormControl>

        <tag-selector ref={tagSelectorRef} tag-target="./mealplan/tag"></tag-selector>

        <FormControl margin="normal" style={{ paddingLeft: 10 }}>
          <Tooltip title={<Typography>Search Recipe</Typography>}>
            <Fab color="primary" type="submit" variant="extended">
              Search
            </Fab>
          </Tooltip>
        </FormControl>
      </FormGroup>
    </form>
  )
}
