import { Grid } from "@material-ui/core"
import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { ListRecipe as ReactListRecipe } from "../React/ListRecipe"
import { SearchRecipe as ReactSearchRecipe } from "../React/SearchRecipe"

export default class SearchRecipes extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target"]
  }

  constructor() {
    super()

    /**
     * @type {Array<import("../Types").Recipe>}
     */
    this.recipes = []

    this.jsxRootComponent = () => (
      <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
        <Grid item xs={12}>
          <ReactSearchRecipe
            searchRecipe={async (search) => {
              await this.getRecipes(this.getAttribute("target"), search)
              this.render()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ReactListRecipe
            recipes={this.recipes}
            recipeSelected={(recipe) => this.recipeSelected(recipe)}
            updateRecipe={(recipe) => this.updateRecipe(recipe)}
          />
        </Grid>
      </Grid>
    )
  }

  /**
   * @param {import("../Types").Recipe} recipe
   */
  recipeSelected(recipe) {
    this.dispatchEvent(new CustomEvent("recipe-selected", { bubbles: true, composed: true, detail: { recipe } }))
  }

  /**
   * @param {string} url
   * @param {{ searchTerm: string, searchBook: string, tags: any[] }} search
   */
  async getRecipes(url, search) {
    try {
      const token = await window.authorise()

      const getRecipesResponse = await fetch(`${url}`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(search),
      })

      const recipes = /** @type {Array<import("../Types").Recipe>} */ (await getRecipesResponse.json())

      this.recipes = recipes
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  async updateRecipe(recipe) {
    const target = this.getAttribute("target")

    try {
      const token = await window.authorise()

      const getRecipesResponse = await fetch(`${target}/${recipe.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(recipe),
      })

      const recipes = /** @type {Array<import("../Types").Recipe>} */ (await getRecipesResponse.json())

      this.recipes = recipes
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("search-recipe", SearchRecipes)
